export const carouselResponsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1400, min: 464 },
    items: 4,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 1100, min: 0 },
    items: 3,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobileOne: {
    breakpoint: { max: 820, min: 0 },
    items: 2.5,
  },
  mobileTwo: {
    breakpoint: { max: 720, min: 0 },
    items: 2.4,
  },
  mobileThree: {
    breakpoint: { max: 670, min: 0 },
    items: 2.2,
  },
  mobileFour: {
    breakpoint: { max: 620, min: 0 },
    items: 2,
  },
  mobileFive: {
    breakpoint: { max: 560, min: 0 },
    items: 1.8,
  },
  mobileSix: {
    breakpoint: { max: 520, min: 0 },
    items: 1.6,
  },
  mobileSeven: {
    breakpoint: { max: 460, min: 0 },
    items: 1.4,
  },
  mobileEight: {
    breakpoint: { max: 420, min: 0 },
    items: 1.3,
  },
  mobileNine: {
    breakpoint: { max: 380, min: 0 },
    items: 1.1,
  },
  mobileTen: {
    breakpoint: { max: 340, min: 0 },
    items: 1.05,
  },
  mobileEleven: {
    breakpoint: { max: 320, min: 0 },
    items: 1,
  },
}

export const nextLang = (lang: string) => {
  return lang === 'ru' ? 'kz' : lang === 'kz' ? 'en' : 'ru'
}

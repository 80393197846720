export const techStack = {
  technologies: [
    'Spring Boot',
    'Spring Cloud',
    'JHipster',
    'Java',
    'RabbitMQ',
    'Apache Camel',
    'JavaScript',
    'ReactJS, Next.js',
    'Redux, Redux Saga',
    'Bootstrap',
    'K8S',
    'Docker',
    'Ansible',
    'Linux',
    'Red Hat',
    'Nginx',
    'Jenkins',
    'GitLab',
    'Angular',
    'Java',
    'Kotlin',
    'Flutter',
    'PostgreSQL',
    'MongoDB',
    'Elasticsearch',
    'Event Sourcing and CQRS',
    'Hazelcast',
    'ELK',
    'Netflix Constructor',
    'Confluence',
    'Consul',
    'Enonic',
    'Drupal',
    'Strapi',
    'Angular',
    'Ant Design',
    'Swift',
    'Objective C',
    'Jira Software',
  ],
}

import { PartnerForm } from '../components/PartnerForm'
import { useTranslation } from 'next-i18next'
import Image from 'next/image'
import { FC } from 'react'

export const PartnerSection: FC = () => {
  const { t: translate } = useTranslation('common')

  return (
    <div className="relative z-10 bg-[#191919] pb-28 pt-20" id="partners">
      <div className="container">
        <div className="mb-10 max-w-[360px]">
          <p className="text-lg leading-relaxed text-white text-opacity-80">
            {translate('partner-title')}
          </p>
        </div>
        <div className="flex flex-col gap-20 lg:flex-row lg:justify-between lg:gap-0">
          <div className="max-w-[460px] text-white">
            <h1 className="mb-8 text-3xl leading-relaxed">
              {translate('partner-we-value-relation')}
            </h1>
            <ul className="flex flex-col gap-6 text-base leading-relaxed">
              <li className="flex gap-4">
                <div className="relative w-[16px] max-sm:w-[12px]">
                  <Image fill alt="star" src="/icons/star.svg" loading="lazy" />
                </div>
                <div>
                  <h1>
                    {translate('partner-crossplatform-products-realization')}
                  </h1>
                  <p className="text-sm leading-relaxed text-white text-opacity-60">
                    {translate('partner-from-idea-to-conclusion')}
                  </p>
                </div>
              </li>
              <li className="flex gap-4">
                <div className="relative w-[16px] max-sm:w-[12px]">
                  <Image fill alt="star" src="/icons/star.svg" loading="lazy" />
                </div>

                <h1>{translate('partner-creating-mobile-apps')}</h1>
              </li>
              <li className="flex gap-4">
                <div className="relative w-[16px] max-sm:w-[12px]">
                  <Image fill alt="star" src="/icons/star.svg" loading="lazy" />
                </div>

                <h1>{translate('partner-project-managing')}</h1>
              </li>
              <li className="flex gap-4">
                <div className="relative w-[16px] max-sm:w-[12px]">
                  <Image fill alt="star" src="/icons/star.svg" loading="lazy" />
                </div>

                <h1>{translate('partner-it-outsourcing')}</h1>
              </li>
              <li className="flex gap-4">
                <div className="relative w-[16px] max-sm:w-[12px]">
                  <Image fill alt="star" src="/icons/star.svg" loading="lazy" />
                </div>

                <h1>{translate('partner-it-consulting')}</h1>
              </li>
            </ul>
          </div>
          <PartnerForm />
        </div>
      </div>
    </div>
  )
}

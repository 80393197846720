import { FC } from 'react'
import Image from 'next/image'
import { ArrowRight, Timer } from 'lucide-react'
import { IJobProps } from '@/mixins/types'
import CardImage from '@/img/card-bg.jpg'

export const Job: FC<IJobProps> = ({ job }) => {
  const { name, link, time } = job

  return (
    <div className="z-20 h-36 w-64">
      <div style={{ width: '100%', height: '100%', position: 'relative' }}>
        <Image
          alt="Card Image"
          src={CardImage}
          placeholder="blur"
          fill
          sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
          className="absolute z-0"
          loading="lazy"
        />
        <div className="relative z-10 flex h-full flex-col justify-between p-4 text-white">
          <h1 className="w-full text-base font-bold leading-tight">{name}</h1>
          <div className="flex justify-between gap-2">
            <div className="flex items-center gap-2">
              <Timer width={20} strokeWidth={1.5} />
              <span className="text-xs">{time}</span>
            </div>
            <a href={link}>
              <button className="pointer-events-auto">
                <ArrowRight strokeWidth={1.25} />
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

import { Header } from '../components/Header'
import { FC } from 'react'
import { useTranslation } from 'next-i18next'
import desktopImage from '@/img/desktop-bg.jpg'
import Image from 'next/image'

export const WelcomeSection: FC = () => {
  const { t: translate } = useTranslation('common')

  return (
    <div className="relative pb-20">
      <Image
        alt="Background Image"
        src={desktopImage}
        placeholder="blur"
        quality={100}
        fill
        style={{
          objectFit: 'cover',
        }}
        className="z-[-1] overflow-hidden"
        loading="lazy"
      />
      <Header />
      <div className="container relative z-10 overflow-hidden ">
        <div className="absolute right-[-50px] top-[230px] h-[150px] w-[200px] sm:right-[50px] md:top-[110px] lg:h-[200px] lg:w-[280px]">
          <Image fill alt="Donut" src="/icons/donut.svg" loading="lazy" />
        </div>

        <div className="w-[90%] pb-52 pt-[110px] md:w-[660px] md:py-28">
          <h1 className="text-5xl text-white md:leading-snug">
            {translate('main-hero')}
          </h1>
        </div>
        <div className="flex flex-col gap-10 md:flex-row lg:w-[70%] ">
          <p className="leading-relaxed text-white">
            {translate('main-description-one')}
          </p>
          <p className="leading-relaxed text-white">
            {translate('main-description-two')}
          </p>
        </div>
      </div>
    </div>
  )
}

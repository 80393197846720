import { ITranslate } from '../types'

export const navLinks = (translate: ITranslate) => [
  {
    name: translate('main-partners'),
    id: '#partners',
  },
  {
    name: translate('main-about-us'),
    id: '#about-us',
  },
  {
    name: translate('main-career'),
    id: '#career',
  },
  {
    name: translate('main-contacts'),
    id: '#contacts',
  },
]

import React, { FC, MouseEventHandler } from 'react'

interface IHamburgerBtnProps {
  onClick: MouseEventHandler<HTMLButtonElement>
  active: boolean
}

const HamburgerBtn: FC<IHamburgerBtnProps> = ({ onClick, active }) => {
  return (
    <button
      className=" flex h-[12.5px] w-[22.5px] flex-col items-center justify-center gap-[5px] duration-150 md:hidden"
      onClick={onClick}
    >
      <div
        className={`h-[2px] w-full rounded-full bg-white transition-transform ${
          active ? 'translate-y-[5px] -rotate-45' : 'border-t'
        }`}
      ></div>
      <div
        className={`h-[2px] w-full rounded-full bg-white transition-opacity ${
          active ? 'opacity-0' : 'border-b'
        }`}
      ></div>
      <div
        className={`h-[2px] w-full rounded-full bg-white transition-transform ${
          active ? 'translate-y-[-7px] rotate-45' : 'border-t'
        }`}
      ></div>
    </button>
  )
}

export default HamburgerBtn

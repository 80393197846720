import { FC } from 'react'
import { useTranslation } from 'next-i18next'
import { YMaps, Map, Placemark, ZoomControl } from '@pbe/react-yandex-maps'

const Location: FC = () => {
  const defaultState = {
    center: [51.129263, 71.428549],
    zoom: 18,
  }
  return (
    <YMaps
      enterprise
      query={{ apikey: '29b9c485-e989-4dd4-8607-7e535968c477' }}
    >
      <Map defaultState={defaultState} className="h-96 w-full md:h-[500px]">
        <Placemark geometry={[51.129263, 71.428549]} />
        <ZoomControl />
      </Map>
    </YMaps>
  )
}

export const ContactSection: FC = () => {
  const { t: translate } = useTranslation('common')

  return (
    <>
      <div
        className="relative z-10 bg-[#191919] pb-0  pt-14 lg:py-14"
        id="contacts"
      >
        <div className="mx-auto flex max-w-[1400px] flex-col gap-10 lg:flex-row lg:px-8">
          <div className="w-full px-8 lg:w-1/2 lg:px-0 lg:pt-14">
            <div className="text-white">
              <h1 className="mb-8 text-3xl">
                {translate('contact-contact-with-us')}
              </h1>
              <div className="flex flex-col gap-5">
                <p>
                  {translate('contact-location')}
                  <br />
                  {translate('contact-address')}
                  <br />
                  {translate('contact-building')}
                </p>
                <p>
                  {translate('contact-email-us')}
                  <br />
                  {translate('contact-become-partners')}
                  <br />
                  {translate('contact-work-with-us')}
                </p>
                <p>
                  {translate('contact-contacts')}
                  <br />
                  +7 771 072 47 89
                </p>
              </div>
            </div>
          </div>
          <div className="w-full lg:w-1/2">
            <Location />
          </div>
        </div>
      </div>
    </>
  )
}

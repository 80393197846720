import { useTranslation } from 'next-i18next'
import React, { FC } from 'react'
import Image from 'next/image'
import desktopImage from '@/img/desktop-bg.jpg'

export const AboutSection: FC = () => {
  const { t: translate } = useTranslation('common')

  return (
    <>
      <div className="relative z-10 pb-14" id="about-us">
        <Image
          alt="Background Image"
          src={desktopImage}
          placeholder="blur"
          quality={100}
          fill
          style={{
            objectFit: 'cover',
          }}
          className="z-[-1] overflow-hidden"
          loading="lazy"
        />
        <div className="z-10 mb-14 min-h-[170px] bg-black bg-opacity-[0.2] py-10 md:py-14">
          <div className="container relative">
            <div className="absolute right-0 top-32 h-36 w-36 sm:h-48 sm:w-48 md:top-10">
              <Image fill alt="Group" src="/icons/group.svg" loading="lazy" />
            </div>
            <div className="flex flex-col gap-10 md:flex-row md:gap-24 ">
              <p className="max-w-[300px] text-lg leading-relaxed text-white">
                {translate('about-professional-team')}
              </p>
              <p className="max-w-[340px] text-lg leading-relaxed text-white">
                {translate('about-astana-hub-residents')}
              </p>
            </div>
          </div>
        </div>
        <div className="container relative z-10">
          <h1 className="mb-10 max-w-[384px] text-3xl leading-relaxed text-white">
            {translate('about-experts-portfolio')}
          </h1>
          <div className="border-2 border-white p-5">
            <div className="flex flex-col justify-between gap-5 md:flex-row">
              <h2 className="font-bold leading-relaxed text-white">B2G</h2>
              <div className="max-w-[150px]">
                <h2 className="text-sm leading-relaxed text-white">egov.kz</h2>

                <h3 className="text-sm leading-relaxed text-white text-opacity-60">
                  {translate('about-qr-electronic-gov')}
                </h3>
              </div>
              <div className="max-w-[270px]">
                <h2 className="text-sm leading-relaxed text-white">
                  sud.gov.kz
                </h2>
                <h3 className="text-sm leading-relaxed text-white text-opacity-60">
                  {translate('about-informational-analytic-system')}
                </h3>
              </div>
              <div className="max-w-[250px]">
                <h2 className="text-sm leading-relaxed text-white">ИСНА</h2>
                <h3 className="text-sm leading-relaxed text-white text-opacity-60">
                  {translate('about-taxpayer-system')}
                </h3>
              </div>
            </div>
          </div>
          <div className="flex min-h-[165px] flex-col md:flex-row">
            <div className="w-full border-x-2 border-b-2 border-white p-5 md:w-1/3">
              <div>
                <h2 className="mb-5 font-bold leading-relaxed text-white">
                  {translate('about-area')}
                </h2>
                <div className="flex gap-10">
                  <h3 className="text-sm leading-relaxed text-white">ИСЭЗ</h3>
                  <h3 className="text-sm leading-relaxed text-white">
                    zTender
                  </h3>
                  <h3 className="text-sm leading-relaxed text-white">
                    e-torgi
                  </h3>
                </div>
              </div>
            </div>
            <div className="w-full border-x-2 border-b-2 border-white p-5 md:w-1/3 md:border-l-0">
              <div>
                <h2 className="mb-5 font-bold leading-relaxed text-white">
                  {translate('about-gas-section')}
                </h2>
                <div className="flex gap-10">
                  <h3 className="text-sm leading-relaxed text-white">ГИС</h3>
                  <h3 className="text-sm leading-relaxed text-white">
                    Өндіріс
                  </h3>
                  <h3 className="text-sm leading-relaxed text-white">
                    ИАС SGS
                  </h3>
                </div>
              </div>
            </div>
            <div className="w-full border-x-2 border-b-2 border-white p-5 md:w-1/3 md:border-l-0">
              <div>
                <h2 className="mb-5 font-bold leading-relaxed text-white">
                  B2B, B2C
                </h2>
                <div className="flex flex-wrap gap-x-10 gap-y-4">
                  <h3 className="text-sm leading-relaxed text-white">
                    Go Cargo
                  </h3>
                  <h3 className="text-sm leading-relaxed text-white">
                    Архив с blockchain
                  </h3>
                  <h3 className="text-sm leading-relaxed text-white">oStudy</h3>
                  <h3 className="text-sm leading-relaxed text-white">
                    Retail Solutions
                  </h3>
                  <h3 className="text-sm leading-relaxed text-white">
                    СЭО Kezek
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="relative z-10 bg-[#191919] bg-cover py-14">
        <div className="container">
          <div className="border-white">
            <h1 className="mb-10 max-w-[320px] text-3xl leading-tight text-white">
              {translate('about-title')}
            </h1>
            <div className="grid grid-cols-8">
              <div className="col-span-8 border-2 px-5 py-4 md:px-7">
                <Image
                  alt="Rings"
                  src="/icons/rings.svg"
                  width={48}
                  height={12}
                />
              </div>
              <div className="col-span-8 border-x-2 border-b-2 p-5 md:col-span-4 md:border-r-0 md:p-8">
                <div className="mb-10 flex gap-5">
                  <div className="relative h-[80px] w-[80px] shrink-0">
                    <Image
                      fill
                      style={{
                        objectFit: 'contain',
                      }}
                      alt="Logo2"
                      src="/icons/logo2.svg"
                      loading="lazy"
                      className="w-20"
                    />
                  </div>
                  <h1 className="root:text-lg max-w-[320px] font-bold text-white">
                    {translate('about-integrated-system-isna')}
                  </h1>
                </div>
                <div className="root:gap-20 flex gap-5 sm:gap-40 md:justify-between md:gap-5">
                  <div>
                    <h3 className="text-lg text-white text-opacity-60">
                      {translate('about-years-realization')}
                    </h3>
                    <h2 className="leading-relaxed text-white md:text-2xl">
                      2019-2023
                    </h2>
                  </div>
                  <div>
                    <h3 className="text-lg text-white text-opacity-60">
                      {translate('about-customer')}
                    </h3>
                    <h2 className="leading-relaxed text-white md:text-2xl">
                      КГД МФ РК
                    </h2>
                  </div>
                </div>
              </div>
              <div className="col-span-8 flex justify-end border-x-2 border-b-2 md:col-span-4">
                <Image
                  alt="laptop"
                  src="/icons/laptop.svg"
                  width={600}
                  height={400}
                  loading="lazy"
                />
              </div>
              <div className="col-span-8 border-x-2 p-5 md:p-8">
                <h1 className="mb-12 text-3xl font-bold leading-relaxed text-white">
                  {translate('about-project-scale')}
                </h1>
                <div className=" flex flex-col gap-14 md:mb-10 md:flex-row md:gap-0">
                  <div className="relative w-full justify-center sm:flex md:w-[60%]">
                    <MobileDiagram />
                    <DesktopDiagram />
                  </div>
                  <div className="flex w-full md:w-[40%]">
                    <div className="w-full md:ml-8 md:max-w-[250px]">
                      <h2 className="mb-2 text-base leading-relaxed text-white">
                        {translate('about-users-number')}
                      </h2>
                      <p className="mb-10 text-sm leading-relaxed text-white text-opacity-60">
                        {translate('about-more-than-20-million')}
                      </p>
                      <h2 className="mb-2 text-base leading-relaxed text-white">
                        {translate('about-concurrent-users-number')}
                      </h2>
                      <p className="text-sm leading-relaxed text-white text-opacity-60">
                        {translate('about-users-planned')}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-span-8 flex border-2 p-5 md:p-8">
                <div className="flex w-full flex-col gap-8 sm:flex-row">
                  <div className="whitespace-nowrap">
                    <h1 className="text-2xl font-bold leading-tight text-white">
                      {translate('about-project-capacity')}
                    </h1>
                  </div>
                  <div className="xs:w-52 flex flex-wrap gap-8 text-sm leading-relaxed sm:w-full">
                    <p className="text-white sm:w-40">
                      {translate('about-processed-objects-number')}
                    </p>
                    <p className="text-white sm:w-40">
                      {translate('about-information-volume')}
                    </p>
                    <p className="text-white sm:w-40">
                      {translate('about-transactions-number')}
                    </p>
                    <p className="text-white sm:w-40">
                      {translate('about-services-and-systems')}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const DesktopDiagram = () => {
  return (
    <div className="relative hidden h-[230px] w-[370px] md:block">
      <Image
        alt="frame2"
        src="/icons/Frame2.svg"
        className="absolute top-[30px]"
        width={48}
        height={185}
        style={{ width: 48, height: 185 }}
        loading="lazy"
      />
      <Image
        alt="frame3"
        src="/icons/Frame3.svg"
        className="absolute left-[67px]"
        width={115}
        height={48}
        style={{ width: 115, height: 48 }}
        loading="lazy"
      />
      <Image
        alt="frame4"
        className="absolute bottom-0 left-[67px]"
        src="/icons/Frame4.svg"
        width={115}
        height={48}
        style={{ width: 115, height: 48 }}
        loading="lazy"
      />
      <div className="absolute left-[120px] top-[90px] flex min-w-[250px] justify-between">
        <Image
          alt="user"
          src="/icons/user.svg"
          width={40}
          height={40}
          style={{ width: 40, height: 40 }}
          loading="lazy"
        />
        <Image
          alt="arrowright"
          src="/icons/arrowright.svg"
          width={45}
          height={32}
          style={{ width: 45, height: 32 }}
          loading="lazy"
        />
        <Image
          alt="frame1"
          src="/icons/Frame1.svg"
          width={108}
          height={48}
          style={{ width: 108, height: 48 }}
          loading="lazy"
        />
      </div>
    </div>
  )
}

const MobileDiagram = () => {
  return (
    <div className="flex w-full flex-col  items-center justify-center gap-7 md:hidden">
      <Image
        alt="frame652"
        src="/icons/mobile/Frame 652.svg"
        width={185}
        height={48}
        style={{ width: 185, height: 48 }}
        loading="lazy"
      />
      <div className="flex w-full min-w-[240px] max-w-[300px] justify-between">
        <Image
          alt="frame654"
          src="/icons/mobile/Frame 654.svg"
          width={48}
          height={116}
          style={{ width: 48, height: 116 }}
          loading="lazy"
        />
        <div className="flex h-[200px] flex-col items-center justify-between pt-10">
          <Image
            alt="user"
            src="/icons/user.svg"
            width={40}
            height={40}
            style={{ width: 40, height: 40 }}
            loading="lazy"
          />
          <Image
            alt="arrowdown"
            src="/icons/mobile/arrowdown.svg"
            width={64}
            height={64}
            style={{ width: 64, height: 64 }}
            loading="lazy"
          />
        </div>
        <Image
          alt="frame653"
          src="/icons/mobile/Frame 653.svg"
          width={48}
          height={115}
          style={{ width: 48, height: 115 }}
          loading="lazy"
        />
      </div>
      <Image
        alt="frame651"
        src="/icons/mobile/Frame 651.svg"
        width={48}
        height={108}
        style={{ width: 108, height: 48 }}
        loading="lazy"
      />
    </div>
  )
}

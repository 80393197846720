import { navLinks } from '@/mixins/data/navlinks'
import Link from 'next/link'
import { FC, MouseEventHandler } from 'react'
import { ArrowRight } from 'lucide-react'
import { LocaleButton } from './ui/LocaleButton'
import { ITranslate } from '@/mixins/types'

interface IMobileNavbarProps {
  onClick: MouseEventHandler<HTMLAnchorElement>
  active: boolean
  translate: ITranslate
}

export const MobileNavbar: FC<IMobileNavbarProps> = ({
  active,
  onClick,
  translate,
}) => {
  return (
    <div
      className={`fixed top-0 block py-[100px] duration-150 md:hidden  ${
        active ? 'left-0 overflow-hidden' : 'left-full'
      } z-40 min-h-screen w-full bg-[#191919]`}
    >
      <div className="h-[50px] w-full p-8 ">
        <ul className="mb-14 flex flex-col items-center ">
          {navLinks(translate).map(({ name, id }, idx) => (
            <Link
              key={idx}
              href={id}
              scroll={false}
              onClick={onClick}
              className="w-full rounded-xl py-6 text-center text-lg text-white transition duration-300 ease-out hover:bg-[#252525] focus:bg-opacity-[0.5]"
            >
              {name}
            </Link>
          ))}
        </ul>
        <Link
          className="relative mb-4 flex w-full justify-center rounded-xl bg-white py-6 text-[#191919] transition duration-300 ease-out hover:bg-opacity-[0.9]"
          href="#partners"
          scroll={false}
          onClick={onClick}
        >
          <span className="text-lg">{translate('main-connect')}</span>
          <ArrowRight
            size={20}
            strokeWidth={1.5}
            className="absolute right-6 ml-1"
          />
        </Link>
        <LocaleButton />
      </div>
    </div>
  )
}

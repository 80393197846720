import { FC } from 'react'
import { Job } from './Job'
import { IJob, IJobCarouselProps } from '@/mixins/types'
// import { exampleJobs } from '@/mixins/data/example-jobs'
import Link from 'next/link'
import { ArrowRight } from 'lucide-react'

export const JobCarousel: FC<IJobCarouselProps> = ({ jobs, translate }) => {
  return (
    <div
      className={`flex flex-wrap items-end  ${jobs.length ? 'gap-5' : 'gap-0'}`}
    >
      <div className="flex flex-wrap gap-4">
        {jobs.map((item: IJob, ind: number) => {
          return <Job key={ind} job={item} />
        })}
      </div>
      <Link
        href="https://kokshetau.hh.kz/employer/9437241?hhtmFrom=vacancy"
        className="relative z-50 flex w-max items-end gap-2 px-4 py-2 text-white transition duration-300 ease-out hover:bg-white hover:text-black"
      >
        <div className="text-lg font-bold leading-relaxed">{translate('career-watch-all')}</div>
        <ArrowRight />
      </Link>
    </div>
  )
}

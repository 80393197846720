import { navLinks } from '@/mixins/data/navlinks'
import { ArrowRight } from 'lucide-react'
import Link from 'next/link'
import { FC, useEffect, useState } from 'react'
import Image from 'next/image'
import HamburgerBtn from './ui/HamburgerBtn'
import { MobileNavbar } from './MobileNavbar'
import ScrollToTopButton from './ui/ScrollToUp'
import { useTranslation } from 'next-i18next'
import { LocaleButtonRound } from './ui/LocaleButton'

export const Header: FC = () => {
  const { t: translate } = useTranslation('common')

  const [isOpen, setIsOpen] = useState<boolean>(false)

  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : 'auto'
  }, [isOpen])

  return (
    <div className="container">
      {!!!isOpen && <ScrollToTopButton />}
      <div
        className={`relative left-0 top-0 z-50 flex h-[70px] items-center justify-between leading-snug`}
      >
        <div className="flex items-center">
          <div className="relative z-50 md:mr-20 lg:mr-24">
            <Link href="/">
              <Image
                src="/icons/logo.svg"
                width={102}
                height={24}
                alt="Adamant Systems"
                loading="lazy"
              />
            </Link>
          </div>
          <ul className="hidden items-center md:flex md:gap-5 [@media(min-width:900px)]:gap-10 ">
            {navLinks(translate).map(({ name, id }, idx) => (
              <li key={idx}>
                <Link href={id} scroll={false}>
                  <span className="text-sm leading-snug text-white">
                    {name}
                  </span>
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <div className="flex items-center gap-4">
          <LocaleButtonRound />

          <Link
            className="hidden gap-[7px] rounded-[7px] border border-white px-[10px] py-2 text-white transition duration-300 ease-out hover:bg-white hover:text-black md:flex"
            href="#partners"
            scroll={false}
          >
            <span className="text-sm ">{translate('main-connect')}</span>
            <ArrowRight size={20} className="ml-1" />
          </Link>
        </div>
        <HamburgerBtn active={isOpen} onClick={() => setIsOpen(!isOpen)} />
      </div>
      <div className="h-[1px] w-full bg-white"></div>
      <div
        className={`fixed ${
          isOpen ? 'block' : 'hidden'
        } inset-0 z-[30] h-full w-screen `}
      ></div>
      <MobileNavbar
        active={isOpen}
        onClick={() => setIsOpen(!isOpen)}
        translate={translate}
      />
    </div>
  )
}

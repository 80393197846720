import Head from 'next/head'
import { WelcomeSection } from '@/parts/WelcomeSection'
import { PartnerSection } from '@/parts/PartnerSection'
import { ContactSection } from '@/parts/ContactSection'
import { AboutSection } from '@/parts/AboutSections'
import { CareerSection } from '@/parts/CareerSection'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import 'react-multi-carousel/lib/styles.css'
import { GetStaticProps, GetStaticPropsContext } from 'next'

const Home = () => {
  return (
    <>
      <Head>
        <title>Adamant Systems</title>
      </Head>
      <>
        <WelcomeSection />
        <PartnerSection />
        <AboutSection />
        <CareerSection />
        <ContactSection />
      </>
    </>
  )
}

export const getStaticProps: GetStaticProps = async (
  context: GetStaticPropsContext
) => {
  const { locale } = context

  return {
    props: {
      ...(await serverSideTranslations(locale!, ['common'])),
    },
  }
}

export default Home

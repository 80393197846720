import { FC, useState } from 'react'
import { ArrowRight, LoaderIcon } from 'lucide-react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Input } from './ui/input'
import emailjs from '@emailjs/browser'
import { useTranslation } from 'next-i18next'

type Inputs = {
  company_name: string
  contact_person: string
  field_of_activity: string
}

export const PartnerForm: FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>()

  const [disabled, setDisabled] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  const { t: translate } = useTranslation('common')

  const sendEmail: SubmitHandler<Inputs> = () => {
    setLoading(true)
    const form = document.querySelector('form')

    emailjs
      .sendForm(
        'service_7cfdxgn',
        'template_ikyrsxc',
        form!,
        'Gs7W2HU7RJBb5lvWJ'
      )
      .then(
        () => {
          // console.log('Form sent successfully!');
          setDisabled(true)
          setLoading(false)
        },
        (error) => {
          console.log(error.text)
        }
      )
  }

  return (
    <div className="w-full text-white md:w-[400px]">
      <h1 className="mb-8 text-3xl leading-relaxed">
        {translate('partner-become-our-partners')}
      </h1>
      <form
        onSubmit={handleSubmit(sendEmail)}
        className="flex w-full flex-col gap-8 text-sm font-semibold leading-relaxed sm:w-80"
      >
        <div className="relative">
          <Input
            {...register('company_name', {
              required: true,
              maxLength: 100,
              minLength: 20,
            })}
            type="text"
            placeholder={translate('partner-placeholder.input-company')}
            className={`${
              errors.company_name
                ? 'border-red-500'
                : 'border-white border-opacity-[0.6] focus:border-opacity-[1]'
            }`}
          />
          {errors.company_name && errors.company_name.type === 'maxLength' && (
            <span className="absolute -bottom-5 left-0 text-xs text-red-500">
              {translate('partner-placeholder.maxLength')}
            </span>
          )}
          {errors.company_name && errors.company_name.type === 'minLength' && (
            <span className="absolute -bottom-5 left-0 text-xs text-red-500">
              {translate('partner-placeholder.minLength')}
            </span>
          )}
        </div>
        <div className="relative">
          <Input
            {...register('contact_person', {
              required: true,
              maxLength: 100,
              minLength: 20,
            })}
            type="text"
            placeholder={translate('partner-placeholder.input-contact')}
            className={`${
              errors.contact_person
                ? 'border-red-500'
                : 'border-white border-opacity-[0.6] focus:border-opacity-[1]'
            }`}
          />
          {errors.contact_person &&
            errors.contact_person.type === 'maxLength' && (
              <span className="absolute -bottom-5 left-0 text-xs text-red-500">
                {translate('partner-placeholder.maxLength')}
              </span>
            )}
          {errors.contact_person &&
            errors.contact_person.type === 'minLength' && (
              <span className="absolute -bottom-5 left-0 text-xs text-red-500">
                {translate('partner-placeholder.minLength')}
              </span>
            )}
        </div>
        <div className="relative">
          <textarea
            {...register('field_of_activity', {
              required: true,
              maxLength: 100,
              minLength: 20,
            })}
            placeholder={translate('partner-placeholder.input-activity')}
            className={`flex w-full resize-none border-b  bg-transparent pb-3 text-sm  outline-none placeholder:text-sm placeholder:font-semibold placeholder:text-white placeholder:text-opacity-60  ${
              errors.field_of_activity
                ? 'border-red-500'
                : 'border-white border-opacity-[0.6] focus:border-opacity-[1]'
            }`}
            rows={3}
          />
          {errors.field_of_activity &&
            errors.field_of_activity.type === 'maxLength' && (
              <span className="absolute -bottom-5 left-0 text-xs text-red-500">
                {translate('partner-placeholder.maxLength')}
              </span>
            )}
          {errors.field_of_activity &&
            errors.field_of_activity.type === 'minLength' && (
              <span className="absolute -bottom-5 left-0 text-xs text-red-500">
                {translate('partner-placeholder.minLength')}
              </span>
            )}
        </div>
        <div className="flex justify-end">
          <button
            type="submit"
            disabled={disabled}
            className={`flex w-40 items-center justify-center gap-2 transition duration-300 ease-out ${
              disabled || loading
                ? 'bg-white text-black'
                : ' text-white hover:bg-white hover:text-black'
            } px-4 py-2`}
          >
            {disabled ? (
              <div className="text-lg font-bold leading-relaxed ">
                {translate('partner-sent')}
              </div>
            ) : (
              <>
                <div className="text-lg font-bold leading-relaxed">
                  {translate('partner-button.send')}
                </div>
                {loading ? (
                  <LoaderIcon
                    className="animate-spin text-black duration-75"
                    size={20}
                  />
                ) : (
                  <ArrowRight />
                )}
              </>
            )}
          </button>
        </div>
      </form>
    </div>
  )
}

import { nextLang } from '@/mixins/helpers'
import { Globe } from 'lucide-react'
import { useRouter } from 'next/router'
import { FC } from 'react'

export const LocaleButtonRound: FC = () => {
  const { locale, push } = useRouter()
  return (
    <button
      className="hidden h-9 w-9 appearance-none items-center rounded-full bg-white bg-opacity-[0.2] text-center text-sm leading-none text-white transition duration-300 ease-out hover:bg-opacity-[0.25] focus:outline-none md:flex md:justify-center"
      onClick={() => push('/', undefined, { locale: nextLang(locale!) })}
    >
      {locale?.toUpperCase()}
    </button>
  )
}

export const LocaleButton: FC = () => {
  const { locale, push } = useRouter()
  return (
    <button
      onClick={() => push('/', undefined, { locale: nextLang(locale!) })}
      className="relative flex w-full justify-center rounded-xl bg-[#252525] py-6 text-xl text-white transition duration-300 ease-out hover:bg-[#202020]"
    >
      <span className="text-lg">{locale?.toUpperCase()}</span>
      <Globe size={20} strokeWidth={1.5} className="absolute right-6 ml-1" />
    </button>
  )
}

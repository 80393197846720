import { ArrowUp } from 'lucide-react'
import React, { useState, useEffect, FC } from 'react'

const ScrollToTopButton: FC = () => {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    // Show the scroll button when the user scrolls down 20px from the top
    const handleScroll = () => {
      if (window.pageYOffset > 20) {
        setIsVisible(true)
      } else {
        setIsVisible(false)
      }
    }

    window.addEventListener('scroll', handleScroll)

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  return (
    <button
      className={`fixed bottom-5 right-8 z-30 rounded-full border border-white border-opacity-[0.1] bg-[#191919] p-3 shadow-xl  ${
        isVisible ? 'block' : 'hidden'
      }`}
      onClick={scrollToTop}
      title="Scroll to Top"
    >
      <ArrowUp color="white" opacity="80%" />
    </button>
  )
}

export default ScrollToTopButton

import { FC } from 'react'
import { JobCarousel } from '../components/JobCarousel'
import { techStack } from '../mixins/data/techstack'
import { useTranslation } from 'next-i18next'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'

const fetchJobs = async () => await axios.get('/api/jobs')

export const CareerSection: FC = () => {
  const { t: translate } = useTranslation('common')
  const { isLoading, data: jobs } = useQuery(['jobs'], fetchJobs)

  return (
    <>
      <div className="bg-[#191919] py-14" id="career">
        <div className="container">
          <>
            <h1 className="mb-[20px] text-3xl leading-tight text-white">
              {translate('career-become-team-part')}
            </h1>
            <p className="mb-14 max-w-[450px] text-sm leading-relaxed text-white">
              {translate('career-great-it-team')}
            </p>
            <h2 className="mb-6 text-2xl leading-tight text-white">
              {translate('career-vacancies')}
            </h2>
          </>
          <>
            {isLoading ? (
              <h1 className="text-white">{translate('career-loading')}</h1>
            ) : (
              <JobCarousel jobs={jobs!.data} translate={translate}/>
            )}
          </>
        </div>
      </div>
      <div className="bg-[#191919] py-14">
        <div className="container">
          <h1 className="mb-8 text-3xl leading-tight text-white">
            {translate('career-tech-stack')}
          </h1>
        </div>
        <div className="relative overflow-y-hidden md:container">
          <div className="relative flex h-[200px] min-w-[1000px] max-w-full flex-wrap items-center gap-2 whitespace-nowrap py-2 md:h-auto">
            {techStack.technologies.map((item, ind) => {
              return (
                <span
                  key={ind}
                  className="w-max rounded-full bg-[#252525] px-5 py-[10px]   leading-tight text-white md:h-auto"
                >
                  {item}
                </span>
              )
            })}
          </div>
        </div>
      </div>
    </>
  )
}
